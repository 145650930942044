import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { Size } from '../../../theme/layout';
import { CoverCaption } from '../caption';
import { CoverMeta } from '../meta';
import { Container, Heading, Media, ContentContainer, Content, Inner, MediaContainer, HeadingLink, ContentInner } from './media.styles';
import { CoverMediaProps, CoverVariant } from './media.types';

export const CoverMedia = ({heading, breadcrumb, date, caption, defaultMedia, portraitMedia, variant, page}:CoverMediaProps) => {
  const themeType = variant === CoverVariant.LIGHT ? "dark" : "light";
  const theme = useBrandTheme(themeType);

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 12},
    [Size.LARGE]: {span: 12},
    [Size.XLARGE]: {span: 12},
    [Size.MAX]: {span: 12},
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        
          <MediaContainer>
            <Media defaultSource={defaultMedia} portraitSource={portraitMedia} />
          </MediaContainer>
          <ContentContainer>
            <ContentInner>
            <Content>
              <CoverMeta breadcrumb={breadcrumb} date={date} monochrome={true} />
              <Heading>{page ? <HeadingLink page={page} label={heading} /> : heading}</Heading>
              {caption && <CoverCaption {...caption} />}
            </Content>
            </ContentInner>
          </ContentContainer>
        
      </Container>
    </ThemeProvider>
  )
};