import styled from "styled-components";
import { CONTAINER, getHeaderAsPixels, getLayout, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Layout, Size, VerticalSpaceVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { LinkInternal } from "../../core/link";
import { ResponsiveMedia } from "../../core/responsiveMedia";
import Color from "color";

export const Container = styled.div`
  ${CONTAINER}
  position: relative;
`

export const ContentContainer = styled.div`
  ${CONTAINER}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
`

export const ContentInner = styled.div`
  ${getLayout(Layout.GRID)}
`

export const Heading = styled.h1`
  ${getTypography(Type.HEADLINE)}
  color: ${({theme}) => theme.primary.hex};
`;

export const HeadingLink = styled(LinkInternal)`
  color: ${({theme}) => theme.primary.hex};
  text-decoration: none;
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => Color(theme.primary.hex).isDark() ? Color(theme.primary.hex).mix(Color("white"), 0.3).toString() : Color(theme.primary.hex).mix(Color("black"), 0.3).toString()};
  }
`

export const MediaContainer = styled.div`
  position: relative;
  margin-inline-start: ${getMarginAsPixels(Size.SMALL, {multiplier:-1})};
  margin-inline-end: ${getMarginAsPixels(Size.SMALL, {multiplier:-1})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-inline-start: ${getMarginAsPixels(Size.MEDIUM, {multiplier:-1})};
    margin-inline-end: ${getMarginAsPixels(Size.MEDIUM, {multiplier:-1})};
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-inline-start: 0;
    margin-inline-end: ${getMarginAsPixels(Size.LARGE, {multiplier:-1})};
    margin-top: ${getHeaderAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-inline-end: ${getMarginAsPixels(Size.XLARGE, {multiplier:-1})};
  }

  &:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: ${({theme}) => `linear-gradient(${Color(theme.background.hex).alpha(0)}, ${Color(theme.background.hex).alpha(0.3)});`};
  }
`

export const Media = styled(ResponsiveMedia)`

`

export const Content = styled.div`
  grid-column: 1 / span 8;
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  
  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 2 / span 11;
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 4 / span 9;
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`