
import { all } from 'ramda';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandImage, useBrandTheme } from '../../../../context/brand';
import { useDictionary } from '../../../../context/dictionary';
import { Layout } from '../../../../theme/layout';
import { BlockContainer } from '../../../block/container';
import { ShowList } from '../../../show/showList/showList.component';
import { ShowSlider } from '../../../show/showSlider';
import { BodyTvProps } from './tv.types';

export const BodyTv = ({shows,showsByTopic,topics}:BodyTvProps) => {
  const labelTvLatestShows = useDictionary("labelTvLatestShows");
  const labelTvShowsCount = useDictionary("labelTvShowsCount");
  const labelTvMoreShows = useDictionary("labelTvMoreShows");

  const themeLight = useBrandTheme("light");

  return (
    <>
      <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
        <ShowSlider items={shows} heading={labelTvLatestShows} />
      </BlockContainer>
      <ThemeProvider theme={themeLight}>
        {topics.map((topic,index) => {
          const group = showsByTopic.find((group) => group.topic === topic.id);
          if(group){
            const heading = topic.name;
            const subheading = `${group.totalCount} ${labelTvShowsCount}`;
            return (
              <BlockContainer key={index} layout={Layout.BLOCK} extraSpaceBefore={index === 0} extraSpaceAfter={index === topics.length - 1}>
                <ShowList items={group.shows} link={{label:labelTvMoreShows,page:topic}} heading={heading} subheading={subheading} />
              </BlockContainer>
            )
          }
          return null;
        })}
      </ThemeProvider>
    </>
  )
};