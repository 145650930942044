import React, { useState } from "react"

import { ShowSliderProps } from "./showSlider.types";
import { ShowCard } from "../showCard";
import { CardLayout } from "../../core/card/card.types";
import { CardContainer, CardContainerProgress, CardContainerSliderContent, CardContainerSliderItem } from "../../core/cardContainer";

export const ShowSlider = ({ items, link, heading, subheading }:ShowSliderProps) => {
  const [index, setIndex] = useState();
  const [maxIndex, setMaxIndex] = useState();

  const handleActiveIndexChange = (event) => {
    setIndex(event.index);
    setMaxIndex(event.maxIndex);
  }

  return (
    <CardContainer heading={heading} subheading={subheading} link={link}>
      <CardContainerSliderContent onActiveIndexChange={handleActiveIndexChange}>
        {items.map((item, index) => <CardContainerSliderItem key={item.id}><ShowCard show={item} mobileLayout={CardLayout.VERTICAL} desktopLayout={CardLayout.VERTICAL} /></CardContainerSliderItem>)}
      </CardContainerSliderContent>
      <CardContainerProgress index={index} maxIndex={maxIndex} />
    </CardContainer>
  )
}