import React from "react";
import { Breadcrumb, Container, Date } from "./meta.styles";
import { CoverMetaProps } from "./meta.types";

export const CoverMeta = ({breadcrumb, date, monochrome}:CoverMetaProps) => {
  if(breadcrumb || date){
    return (
      <Container>
        {breadcrumb && <Breadcrumb page={breadcrumb} label={breadcrumb.title || breadcrumb.name} monochrome={monochrome} />}
        {date && <Date>{date}</Date>}
      </Container>   
    )
  }

  return null;
};