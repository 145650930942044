import { LinkExternal, LinkInternal, MediaInternal, Theme } from "../../../common/types";
import { DatoCmsBlogArticle, DatoCmsBlogAuthor, DatoCmsBlogTopic, DatoCmsTvShow } from "../../../common/types/graphql";

export interface CoverMediaProps{
  heading: string;
  breadcrumb?: DatoCmsBlogTopic | DatoCmsTvShow;
  page?:DatoCmsBlogArticle;
  date?: string;
  defaultMedia: MediaInternal;
  portraitMedia: MediaInternal;
  caption?: {
    label: string;
    value: Array<DatoCmsBlogAuthor>;
  }
  variant: CoverVariant;
}

export enum CoverVariant {
  LIGHT = "Light",
  DARK = "Dark",
}