import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { CoverMedia } from '../components/cover/media';
import { BodyTv } from '../components/layout/body/tv';

export const query = graphql`
  query TvHomeQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsTvHome(id: { eq: $id }) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      coverMedia{
        ...MediaCover
      }
      coverMediaPortrait{
        ...MediaCoverPortrait
      }
      coverVariant
      title
    }
    shows: allDatoCmsTvShow(
      sort: {fields: meta___updatedAt, order: DESC},
      filter: {slug: {ne: null}, thumbnail: {url: {ne: null}}, locale: {eq: $locale}},
      limit: 6
    ) {
      nodes {
        ...Show
      }
    }
    showsByTopic: allDatoCmsTvShow(
      sort: {fields: meta___updatedAt, order: DESC},
      filter: {slug: {ne: null}, thumbnail: {url: {ne: null}}, locale: {eq: $locale}}
    ) {
      group(field: topics___id) {
        topic: fieldValue
        shows: nodes {
          ...Show
        }
        totalCount
      }
    }
    topics: allDatoCmsTvTopic(
      filter: {slug: {ne: null}, locale: {eq: $locale}}
    ) {
      nodes {
        ...TvTopic
      }
    }
  }
`;

const TvHome = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const site = prop('site', data);
  const topics = path(['topics','nodes'], data);
  const shows = path(['shows','nodes'], data);
  const showsByTopic = path(['showsByTopic','group'], data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <CoverMedia variant={page.coverVariant} heading={page.title} defaultMedia={page.coverMedia} portraitMedia={page.coverMediaPortrait} />
      <BodyTv topics={topics} shows={shows} showsByTopic={showsByTopic} />
    </Fragment>
  );
};

export default TvHome;