import Color from "color"
import styled from "styled-components"
import { getMarginAsPixels, getMediaQuery, getUnitAsPixels, Size } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"
import { LinkInternal } from "../../core/link"

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${getUnitAsPixels({multiplier:1})};
  position: relative;

  &:before{
    content: "";
    width: ${getUnitAsPixels()};
    height: ${getUnitAsPixels()};
    background-color: ${({theme}) => theme.primary.hex};
    margin-inline-end: ${getUnitAsPixels()};
  }

  > * {
    margin-inline-end: ${getUnitAsPixels()};
  }
`

export const Breadcrumb = styled(LinkInternal)<{monochrome?:boolean}>`
  ${getTypography(Type.LABEL_1)}
  text-decoration: none;
  color: ${({monochrome, theme}) => monochrome ? theme.primary.hex : theme.secondary.hex};
  white-space: nowrap;
  transition: color 0.3s;

  &:hover{
    color: ${({monochrome, theme}) => monochrome ? Color(theme.primary.hex).isDark() ? Color(theme.primary.hex).mix(Color("white"), 0.3).toString() : Color(theme.primary.hex).mix(Color("black"), 0.3).toString() : theme.primary.hex};
  }
`

export const Date = styled.div`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
`